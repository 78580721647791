import { render, staticRenderFns } from "./OrganismModals.vue?vue&type=template&id=f75f7a92&scoped=true&"
import script from "./OrganismModals.vue?vue&type=script&lang=js&"
export * from "./OrganismModals.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f75f7a92",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {OrganismsProjectOrganismRequestOfferModal: require('/app/components/organisms/project/OrganismRequestOfferModal.vue').default,OrganismsProjectOrganismDownloadBrochureModal: require('/app/components/organisms/project/OrganismDownloadBrochureModal.vue').default})
