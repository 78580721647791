
import modalConstants from '~/store/modal/-constants'

export default {
  name: 'OrganismModals',
  computed: {
    showModal() {
      return this.$store.state.modal.showModal
    },
    modalType() {
      return this.$store.state.modal.modalType
    }
  },
  watch: {
    $route: function (to, from) {
      this.$store.dispatch(modalConstants.withNamespace(modalConstants.action.CLOSE_MODAL))
      document.body.classList.remove('disable-scroll')
    }
  }
}
