import { render, staticRenderFns } from "./OrganismDownloadBrochureModal.vue?vue&type=template&id=108566fc&scoped=true&"
import script from "./OrganismDownloadBrochureModal.vue?vue&type=script&lang=js&"
export * from "./OrganismDownloadBrochureModal.vue?vue&type=script&lang=js&"
import style0 from "./OrganismDownloadBrochureModal.vue?vue&type=style&index=0&id=108566fc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "108566fc",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculesProjectMoleculeDownloadBrochure: require('/app/components/molecules/project/MoleculeDownloadBrochure.vue').default,MoleculesProjectMoleculeSendRequest: require('/app/components/molecules/project/MoleculeSendRequest.vue').default})
